import {
  CommunityJoinDialog,
  CompanyIcon,
  Geogroup,
  HttpService,
  LockIcon,
} from '@geovelo-frontends/commons';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Skeleton,
  Typography,
} from '@mui/material';
import { Link, navigate } from 'gatsby';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import communityImage from '../../assets/images/community.svg';
import Button from '../../components/button';
import Dialog from '../../components/dialog';
import { AppContext } from '../../context';
import environment from '../../environment';

function ItemContent({
  geogroup,
  joined,
  openJoinDialog,
}: {
  geogroup?: Geogroup;
  joined?: boolean;
  openJoinDialog: (open: boolean) => void;
}) {
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  return (
    <CardContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
        '&&': { padding: 2 },
      }}
    >
      <Box display="flex" flexGrow={1} gap={2}>
        <Box flexShrink={0}>
          {geogroup ? (
            <Avatar src={geogroup.photo || communityImage} sx={{ height: 60, width: 60 }} />
          ) : (
            <Skeleton height={60} variant="circular" width={60} />
          )}
        </Box>
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          flexGrow={1}
          gap={2}
          width="calc(100% - 76px)"
        >
          <Box display="flex" flexDirection="column" gap={0.5} width="100%">
            <Typography
              fontWeight={600}
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              variant="body1"
              width="100%"
            >
              {geogroup ? geogroup.title : <Skeleton variant="text" width={200} />}
            </Typography>
            {geogroup && (
              <>
                {geogroup.type === 'company' ? (
                  <Box alignItems="center" display="flex" gap={0.5}>
                    <CompanyIcon color="primary" sx={{ height: 16, width: 16 }} />
                    <Typography color="primary" lineHeight={1} variant="caption">
                      {t('geovelo.communities.joined_communities.company')}
                    </Typography>
                  </Box>
                ) : (
                  geogroup.type !== 'city' &&
                  !geogroup.openMembership && (
                    <Box alignItems="center" display="flex" gap={0.5}>
                      <LockIcon color="primary" sx={{ height: 16, width: 16 }} />
                      <Typography color="primary" lineHeight={1} variant="caption">
                        {t('geovelo.communities.joined_communities.private')}
                      </Typography>
                    </Box>
                  )
                )}
              </>
            )}
            <Typography color="textSecondary" variant="caption">
              {geogroup ? (
                t('geovelo.communities.members', {
                  count: geogroup.nbMembers,
                })
              ) : (
                <Skeleton variant="text" width={100} />
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      {!joined && geogroup && (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {currentUser !== null && (
            <Button
              disableElevation
              color="primary"
              disabled={!currentUser}
              onClick={() => openJoinDialog(true)}
              size="small"
              variant="contained"
            >
              {t('geovelo.communities.actions.join', {
                context: geogroup.type === 'company' ? 'company' : '',
              })}
            </Button>
          )}
        </Box>
      )}
      {geogroup?.offersAccompaniedTrips && (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button
            color="primary"
            component={Link}
            size="small"
            to={`/${currentLanguage}/covelo/${geogroup.code}`}
            variant="outlined"
          >
            Covélo
          </Button>
        </Box>
      )}
    </CardContent>
  );
}

function Item({
  geogroup,
  joined,
  openCompanyMigrationDialog,
  ...props
}: {
  geogroup?: Geogroup;
  joined?: boolean;
  openCompanyMigrationDialog?: () => void;
}): JSX.Element {
  const {
    user: { current: currentUser },
    actions: { getUserGeogroups },
  } = useContext(AppContext);
  const [joinDialogOpen, openJoinDialog] = useState(false);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Card
        sx={{
          borderRadius: '10px',
          width: {
            xs: '100%',
            sm: 'calc((100% - 24px) / 2)',
            lg: 'calc((100% - 48px) / 3)',
          },
        }}
        variant="outlined"
        {...props}
      >
        {geogroup && joined && !geogroup.offersAccompaniedTrips ? (
          <CardActionArea
            sx={{ height: '100%' }}
            {...(geogroup.type === 'company'
              ? geogroup.partner
                ? {
                    component: 'a',
                    href: `${
                      environment.enterpriseUrl
                    }subscription?auth-token=${HttpService.authorizationToken?.substring(
                      HttpService.authorizationToken.indexOf(' ') + 1,
                    )}&user-id=${currentUser?.id}`,
                    target: '_blank',
                    rel: 'noreferrer',
                  }
                : {
                    onClick: () => openCompanyMigrationDialog?.(),
                  }
              : { component: Link, to: `/${currentLanguage}/communities/${geogroup.id}` })}
          >
            <ItemContent geogroup={geogroup} joined={joined} openJoinDialog={openJoinDialog} />
          </CardActionArea>
        ) : (
          <ItemContent geogroup={geogroup} joined={joined} openJoinDialog={openJoinDialog} />
        )}
      </Card>
      <CommunityJoinDialog
        Button={Button}
        currentUser={currentUser}
        Dialog={Dialog}
        geogroup={geogroup}
        onClose={(geogroup, data) => {
          if (geogroup) {
            if (data?.waiting) {
              enqueueSnackbar(t('commons.communities.join_dialog.asked'));
            } else {
              getUserGeogroups();
              navigate(`/${currentLanguage}/communities/${geogroup.id}`);
            }
          } else openJoinDialog(false);
        }}
        open={joinDialogOpen}
      />
    </>
  );
}

export default Item;
