import {
  Autocomplete,
  ComputedRoute,
  ComputedRouteService,
  GeocoderService,
  Place,
  Search,
  TCyclingProfile,
  UserPlace,
  createImageMarker,
  cyclingProfiles,
  useCancellablePromise,
  useTracker,
  useUnits,
} from '@geovelo-frontends/commons';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { WindowLocation } from '@reach/router';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../components/button';
import FormCard from '../../components/form-card';
import { AddIcon, MinusIcon } from '../../components/icons';
import { AppContext } from '../../context';
import useQueryParams from '../../hooks/query-params';

import { MapMouseEvent, Marker } from '!maplibre-gl';

export const loopGeneratorDurationMin = 900;
export const loopGeneratorDurationMax = 10800;
export const loopGeneratorDurationStep = 900;
export const loopGeneratorDistanceMin = 3000;
export const loopGeneratorDistanceMax = 40000;
export const loopGeneratorDistanceStep = 1000;
const profiles: TCyclingProfile[] = ['safe', 'daily', 'direct', 'touristic'];

interface IProps {
  computedRoute: ComputedRoute | null | undefined;
  cyclingProfile: TCyclingProfile;
  location: WindowLocation;
  setComputedRoute: (computedRoute?: ComputedRoute | null) => void;
  setCyclingProfile: (profile: TCyclingProfile) => void;
}

function Form({
  location,
  cyclingProfile,
  computedRoute,
  setCyclingProfile,
  setComputedRoute,
}: IProps): JSX.Element {
  const { trackEvent } = useTracker();
  const { get: getQueryParams, getFrom } = useQueryParams(location.search);
  const [place, setPlace] = useState<Place | null>(() => getFrom() || null);
  const [type, setType] = useState<'duration' | 'distance'>(() => {
    const { type } = getQueryParams();

    return type === 'distance' ? 'distance' : 'duration';
  });
  const [value, setValue] = useState<number>(() => {
    const { value } = getQueryParams();

    return value ? parseInt(value) : type === 'duration' ? 1800 : 10000;
  });
  const [loading, setLoading] = useState(false);
  const marker = useRef<Marker>();
  const {
    user: { places: userPlaces, geolocation },
    map: { current: map },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { toTime, toDistance } = useUnits();

  useEffect(() => {
    return () => {
      cancelPromises();
      marker.current?.remove();
    };
  }, []);

  useEffect(() => {
    let active = true;

    marker.current?.remove();
    setComputedRoute(null);

    if (map) map.on('click', handleMapClick);

    if (place) {
      const {
        point: {
          coordinates: [lng, lat],
        },
      } = place;

      if (!place.addressDetail) {
        try {
          GeocoderService.reverseGeocode(undefined, place.point, (place) => {
            if (active) setPlace(place);
          });
        } catch {
          //
        }
      }

      if (map) {
        marker.current = createImageMarker(
          {
            radius: 6,
            borderWidth: 2,
            backgroundColor: '#2ac682',
            draggable: true,
          },
          {
            onDragEnd: ({ lat, lng }) =>
              setPlace(new Place(undefined, { type: 'Point', coordinates: [lng, lat] })),
          },
        )
          .setLngLat({ lat, lng })
          .addTo(map);

        map.flyTo({ center: { lat, lng }, zoom: Math.max(15, map.getZoom()) });
      }
    }

    return () => {
      active = false;
      map?.off('click', handleMapClick);
    };
  }, [place, map]);

  useEffect(() => {
    if (computedRoute) marker.current?.setDraggable(false);
    else marker.current?.setDraggable(true);
  }, [computedRoute]);

  function handleMapClick({ lngLat: { lat, lng } }: MapMouseEvent) {
    if (!place) {
      setPlace(new Place(undefined, { type: 'Point', coordinates: [lng, lat] }));
    }
  }

  async function handleSubmit() {
    cancelPromises();

    if (!place) return;

    setComputedRoute(undefined);
    setLoading(true);
    marker.current?.setDraggable(false);
    trackEvent('Generate a ride', 'Clicked', 'CTA on loop generation page');

    try {
      const { computedRoutes } = await cancellablePromise(
        ComputedRouteService.compute(
          new Search({
            bikeType: 'own',
            eBikeEnabled: false,
            profile: cyclingProfile,
            transportModes: ['bike'],
            wayPoints: [place],
            loopSettings: {
              visitPois: true,
              ...(type === 'duration' ? { duration: value } : { distance: value }),
            },
          }),
          { singleResult: true, geometry: true, facilities: true },
        ),
      );

      const computedRoute = computedRoutes[0];
      if (!computedRoute) throw new Error('no loop');

      setComputedRoute(computedRoute);
      setLoading(false);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('geovelo.loop_generator.server_error'));
        setLoading(false);
        setComputedRoute(null);
        marker.current?.setDraggable(true);
      }
    }
  }

  const defaultOptions: Array<Place | UserPlace> = [];
  if (geolocation) defaultOptions.push(geolocation);
  if (userPlaces) defaultOptions.push(...userPlaces);

  return (
    <FormCard
      actions={
        <>
          <Button
            color="primary"
            disabled={loading || !place}
            onClick={handleSubmit}
            variant="contained"
          >
            {t('geovelo.loop_generator.actions.generate', {
              context: computedRoute ? 'again' : '',
            })}
          </Button>
        </>
      }
    >
      <StyledStepper orientation="vertical">
        <Step active expanded>
          <StepLabel>
            <Typography variant="subtitle1">
              {t('geovelo.loop_generator.navigation.departure')}
            </Typography>
          </StepLabel>
          <StepContent>
            <Autocomplete
              hideCustomOptionsOnSearch
              center={map?.getCenter()}
              customOptions={defaultOptions}
              defaultValue={place}
              disabled={loading}
              onSelect={setPlace}
              size="small"
            />
          </StepContent>
        </Step>
        <Step active expanded>
          <StepLabel>
            <Typography variant="subtitle1">{t('commons.settings')}</Typography>
          </StepLabel>
          <StepContent>
            <Box alignItems="center" display="flex" flexDirection="row" gap={2} marginBottom={1}>
              <ToggleButtonGroup
                exclusive
                aria-label="Platform"
                color="primary"
                onChange={(_, value) => {
                  setType(value);
                  setValue(value === 'duration' ? 1800 : 10000);
                }}
                value={type}
              >
                <ToggleButton value="duration">{t('commons.duration')}</ToggleButton>
                <ToggleButton value="distance">{t('commons.distance')}</ToggleButton>
              </ToggleButtonGroup>
              <Typography gutterBottom color="textSecondary" variant="body2">
                <Trans
                  components={[
                    <Typography color="primary" component="span" key={0} variant="body2" />,
                  ]}
                  i18nKey={`geovelo.loop_generator.${type}`}
                  values={
                    type === 'duration'
                      ? { duration: toTime(value) }
                      : { distance: toDistance(value, false, 'km') }
                  }
                />
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item>
                <IconButton
                  disabled={
                    loading ||
                    (type === 'duration' && value <= loopGeneratorDurationMin) ||
                    (type === 'distance' && value <= loopGeneratorDistanceMin)
                  }
                  onClick={() =>
                    type === 'duration'
                      ? value > loopGeneratorDurationMin &&
                        setValue(value - loopGeneratorDurationStep)
                      : value > loopGeneratorDistanceMin &&
                        setValue(value - loopGeneratorDistanceStep)
                  }
                  size="small"
                >
                  <MinusIcon />
                </IconButton>
              </Grid>
              <Grid item xs>
                <Slider
                  disabled={loading}
                  max={type === 'duration' ? loopGeneratorDurationMax : loopGeneratorDistanceMax}
                  min={type === 'duration' ? loopGeneratorDurationMin : loopGeneratorDistanceMin}
                  onChange={(_, value) => typeof value === 'number' && setValue(value)}
                  step={type === 'duration' ? loopGeneratorDurationStep : loopGeneratorDistanceStep}
                  value={value}
                />
              </Grid>
              <Grid item>
                <IconButton
                  disabled={
                    loading ||
                    (type === 'duration' && value >= loopGeneratorDurationMax) ||
                    (type === 'distance' && value >= loopGeneratorDistanceMax)
                  }
                  onClick={() =>
                    type === 'duration'
                      ? value < loopGeneratorDurationMax &&
                        setValue(value + loopGeneratorDurationStep)
                      : value < loopGeneratorDistanceMax &&
                        setValue(value + loopGeneratorDistanceStep)
                  }
                  size="small"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <StyledFormControl
              margin="dense"
              size="small"
              style={{ width: 200 }}
              variant="outlined"
            >
              <InputLabel id="profile-label">{t('geovelo.loop_generator.profile')}</InputLabel>
              <Select
                disabled={loading}
                label={t('geovelo.loop_generator.profile')}
                labelId="profile-label"
                onChange={({ target: { value } }) => setCyclingProfile(value as TCyclingProfile)}
                value={cyclingProfile}
              >
                {profiles.map((key) => (
                  <MenuItem key={key} value={key}>
                    {t(cyclingProfiles[key]?.labelKey || '')}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </StepContent>
        </Step>
      </StyledStepper>
    </FormCard>
  );
}

const StyledStepper = styled(Stepper)`
  padding: 0;
`;

const StyledFormControl = styled(FormControl)`
  margin-top: 16px;
`;

export default Form;
