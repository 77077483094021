import { Geogroup, UserGeogroup } from '@geovelo-frontends/commons';
import { Add } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button';
import { SearchIcon } from '../../components/icons';
import { AppContext } from '../../context';

import CompanyMigrationDialog from './company-migration-dialog';
import UserCommunityFormDialog from './form-dialog';
import Item from './item';

function UserCommunities(): JSX.Element {
  const [geogroups, setGeogroups] = useState<UserGeogroup[]>();
  const [formDialogOpen, openFormDialog] = useState(false);
  const [companyMigrationDialogOpen, openCompanyMigrationDialog] = useState(false);
  const [companyToMigrate, setCompanyToMigrate] = useState<Geogroup>();
  const {
    user: { geogroups: userGeogroups },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { transitions } = useTheme();

  useEffect(() => {
    setGeogroups(
      userGeogroups
        ?.filter(({ status }) => status === 'manuallyJoin')
        .sort((a, b) => {
          if (a.group.type === b.group.type) {
            return a.group.title.toLowerCase().localeCompare(b.group.title.toLowerCase());
          }

          if (a.group.type === 'company' && a.group.partner) return -1;
          if (b.group.type === 'company' && b.group.partner) return 1;
          if (a.group.type === 'city') return 1;
          if (b.group.type === 'city') return -1;

          return 0;
        }),
    );
  }, [userGeogroups]);

  function handleFormDialogClose(geogroup?: Geogroup) {
    if (geogroup) navigate(`/${currentLanguage}/communities/${geogroup.id}`);
    else openFormDialog(false);
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          alignItems={{ xs: 'flex-start', md: 'center' }}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={2}
          justifyContent="space-between"
          minHeight={40}
        >
          <Typography fontSize="1.5rem" fontWeight={700}>
            {t('geovelo.communities.joined_communities.title')}
          </Typography>
          {geogroups && geogroups.length > 0 && (
            <Box alignItems="center" columnGap={2} display="flex" flexWrap="wrap" rowGap={1}>
              <Button
                color="primary"
                component={Link}
                size="medium"
                startIcon={<SearchIcon />}
                to={`/${currentLanguage}/communities/search`}
                variant="outlined"
              >
                {t('commons.actions.search')}
              </Button>
              <Button
                color="primary"
                onClick={() => openFormDialog(true)}
                size="medium"
                startIcon={<Add />}
                variant="contained"
              >
                {t('geovelo.communities.actions.create')}
              </Button>
            </Box>
          )}
        </Box>
        {!geogroups ? (
          <Box alignItems="stretch" display="flex" flexWrap="wrap" gap={3}>
            {[1, 2, 3, 4, 5].map((key) => (
              <Item joined key={key} />
            ))}
          </Box>
        ) : (
          <>
            {geogroups.length > 0 ? (
              <Box alignItems="stretch" display="flex" flexWrap="wrap" gap={3}>
                {geogroups.map(({ group }) => (
                  <Item
                    joined
                    geogroup={group}
                    key={group.id}
                    openCompanyMigrationDialog={() => {
                      setCompanyToMigrate(group);
                      openCompanyMigrationDialog(true);
                    }}
                  />
                ))}
              </Box>
            ) : (
              <Box
                alignItems="center"
                border="1px solid rgba(0, 0, 0, 0.12)"
                borderRadius={2}
                display="flex"
                flexDirection="column"
                gap={3}
                justifyContent="center"
                padding={5}
                width="100%"
              >
                <Typography>{t('geovelo.communities.empty_state')}</Typography>
                <Box alignItems="center" columnGap={2} display="flex" flexWrap="wrap" rowGap={1}>
                  <Button
                    color="primary"
                    component={Link}
                    size="small"
                    startIcon={<SearchIcon />}
                    to={`/${currentLanguage}/communities/search`}
                    variant="outlined"
                  >
                    {t('geovelo.communities.actions.search')}
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => openFormDialog(true)}
                    size="medium"
                    startIcon={<Add />}
                    variant="contained"
                  >
                    {t('geovelo.communities.actions.create')}
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
      <UserCommunityFormDialog onClose={handleFormDialogClose} open={formDialogOpen} />
      <CompanyMigrationDialog
        geogroup={companyToMigrate}
        onClose={() => {
          openCompanyMigrationDialog(false);
          setTimeout(() => setCompanyToMigrate(undefined), transitions.duration.leavingScreen);
        }}
        open={companyMigrationDialogOpen}
      />
    </>
  );
}

export default UserCommunities;
