import {
  CompanyIcon,
  GeoveloInlineIcon,
  GeoveloInlineWhiteIcon,
  HttpService,
  PeopleIcon,
  PersonIcon,
  UserAvatar,
  useTracker,
} from '@geovelo-frontends/commons';
import {
  AppBar,
  Box,
  ButtonBase,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Button as MuiButton,
  Popover,
  Skeleton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { Fragment, MouseEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { HeaderDataFragment } from '../../../graphql-types';
import Button from '../../components/button';
import { CloseIcon, MenuIcon, SignOutIcon } from '../../components/icons';
import { AppContext } from '../../context';
import environment from '../../environment';
import { TPageProps } from '../../page-props';

const maxCustomHeaderScrollY = 64;

function Header({
  uri,
  drawerOpen,
  showcase,
  title,
  data,
  toggleDrawer,
}: {
  data?: HeaderDataFragment;
  drawerOpen: boolean;
  showcase?: boolean;
  title: string;
  toggleDrawer: (open: boolean) => void;
} & Omit<TPageProps, 'children' | 'data'>) {
  const { trackEvent } = useTracker();
  const [scrollY, setScrollY] = useState(0);
  const {
    app: { hasHeaderDarkBackground, headerBackgroundColor },
    user: { current: currentUser, employee, signingOut },
    actions: { signOut },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const smallDevice = useMediaQuery('(max-width:999px)');

  useEffect(() => {
    function handleScroll() {
      setScrollY(window.scrollY);
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const links = data?.body.find(({ slice_type }) => slice_type === 'links');

  const darkBackground =
    !drawerOpen && scrollY <= maxCustomHeaderScrollY && hasHeaderDarkBackground;

  return (
    <AppBar
      color="inherit"
      elevation={0}
      sx={({ zIndex }) => ({
        backgroundColor:
          (!drawerOpen && scrollY <= maxCustomHeaderScrollY && headerBackgroundColor) || '#fff',
        borderBottom: `1px solid ${
          drawerOpen || (showcase && scrollY <= maxCustomHeaderScrollY) ? 'transparent' : '#c0c7cc'
        }`,
        color: darkBackground ? '#fff' : 'inherit',
        zIndex: zIndex.drawer + 1,
      })}
    >
      <Toolbar sx={{ '&&': { height: 72, justifyContent: 'stretch', paddingX: 0 } }}>
        <Box
          alignItems="center"
          alignSelf="stretch"
          display="flex"
          flexGrow={1}
          gap={2}
          paddingX={4}
        >
          <Box flexShrink={0} height={32}>
            <StyledLink
              onClick={() => toggleDrawer(false)}
              style={{ display: 'inline-block', height: 32 }}
              title={title}
              to={`/${currentLanguage}`}
            >
              {darkBackground ? (
                <GeoveloInlineWhiteIcon sx={{ height: 'auto', width: 170 }} />
              ) : (
                <GeoveloInlineIcon sx={{ height: 'auto', width: 170 }} />
              )}
            </StyledLink>
          </Box>
          <Box flexGrow={1} />
          <Box
            alignItems="center"
            alignSelf="stretch"
            component="nav"
            display={smallDevice ? 'none' : 'flex'}
            gap={1}
          >
            {links?.items
              .filter(({ item_parent_key }) => !item_parent_key?.text)
              .map(({ item_key, item_link, item_title }, index) => {
                const url = item_link?.url?.replace('https://geovelo.app', '');
                const subItems = links.items.filter(
                  ({ item_parent_key }) =>
                    item_key?.text && item_parent_key?.text === item_key.text,
                );

                const classNames: string[] = [];
                if (darkBackground) classNames.push('white');
                if (
                  subItems.find(
                    ({ item_link }) => item_link?.url?.replace('https://geovelo.app', '') === uri,
                  )
                )
                  classNames.push('active');

                return (
                  <Fragment key={index}>
                    {subItems.length === 0 ? (
                      <NavLink
                        partiallyActive
                        activeClassName="active"
                        className={classNames.join(' ')}
                        to={url || ''}
                      >
                        <Typography
                          align="center"
                          fontWeight={600}
                          lineHeight={1.1}
                          textTransform="initial"
                        >
                          {item_title?.text}
                        </Typography>
                      </NavLink>
                    ) : (
                      <Fragment>
                        <NavButton
                          className={classNames.join(' ')}
                          onClick={({ currentTarget }: MouseEvent<HTMLButtonElement>) =>
                            setAnchorEl(currentTarget)
                          }
                        >
                          <Typography
                            align="center"
                            fontWeight={600}
                            lineHeight={1.1}
                            textTransform="initial"
                          >
                            {item_title?.text}
                          </Typography>
                        </NavButton>
                        <Popover
                          anchorEl={anchorEl}
                          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                          id={`${item_title?.text}-menu`}
                          onClose={handleClose}
                          open={open}
                          PaperProps={{ sx: { borderRadius: '16px' } }}
                          sx={{ marginTop: '8px' }}
                          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                        >
                          <Box display="flex" flexDirection="row" gap={3} padding={2}>
                            {subItems.map(
                              ({
                                icon: _icon,
                                active_icon,
                                item_link: child_link,
                                item_title: child_title,
                              }) => {
                                const icon = _icon && getImage(_icon);
                                const activeIcon = active_icon && getImage(active_icon);

                                return (
                                  <NavLink
                                    activeClassName="active"
                                    className="child-link"
                                    key={child_title?.text}
                                    onClick={() => setAnchorEl(null)}
                                    to={child_link?.url || ''}
                                  >
                                    {icon && (
                                      <GatsbyImage
                                        alt=""
                                        className="child-link-icon"
                                        image={icon}
                                        style={{ marginRight: '8px' }}
                                      />
                                    )}
                                    {activeIcon && (
                                      <GatsbyImage
                                        alt=""
                                        className="child-link-active-icon"
                                        image={activeIcon}
                                        style={{ marginRight: '8px' }}
                                      />
                                    )}
                                    <Typography align="center" textTransform="initial">
                                      {child_title?.text}
                                    </Typography>
                                  </NavLink>
                                );
                              },
                            )}
                          </Box>
                        </Popover>
                      </Fragment>
                    )}
                  </Fragment>
                );
              })}
          </Box>
          {data && (
            <Box alignItems="center" display={smallDevice ? 'none' : 'flex'} flexShrink={0} gap={2}>
              {currentUser ? (
                <>
                  <ButtonBase
                    onClick={({ currentTarget }) => setUserMenuAnchorEl(currentTarget)}
                    sx={{ borderRadius: 5, height: 40, width: 40 }}
                  >
                    <UserAvatar size={40} user={currentUser} />
                  </ButtonBase>
                  <Menu
                    anchorEl={userMenuAnchorEl}
                    onClose={() => setUserMenuAnchorEl(null)}
                    open={Boolean(userMenuAnchorEl)}
                  >
                    <MenuItem
                      dense
                      component={Link}
                      onClick={() => setUserMenuAnchorEl(null)}
                      to={`/${currentLanguage}/user`}
                    >
                      <ListItemIcon>
                        <PersonIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          data.profile_button_label?.text || t('geovelo.navigation.my_profile')
                        }
                      />
                    </MenuItem>
                    <MenuItem
                      dense
                      component={Link}
                      onClick={() => setUserMenuAnchorEl(null)}
                      to={`/${currentLanguage}/communities`}
                    >
                      <ListItemIcon>
                        <PeopleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={t('geovelo.communities.joined_communities.title')} />
                    </MenuItem>
                    {employee && (
                      <MenuItem
                        dense
                        component="a"
                        href={`${
                          environment.enterpriseUrl
                        }?auth-token=${HttpService.authorizationToken?.substring(
                          HttpService.authorizationToken.indexOf(' ') + 1,
                        )}&user-id=${currentUser.id}`}
                      >
                        <ListItemIcon>
                          <CompanyIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="Geovelo Entreprise" />
                      </MenuItem>
                    )}
                    <Divider />
                    <MenuItem dense disabled={signingOut} onClick={() => signOut()}>
                      <ListItemIcon>
                        <SignOutIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('commons.actions.sign_out')} />
                    </MenuItem>
                  </Menu>
                </>
              ) : currentUser === undefined ? (
                <Skeleton height={40} variant="circular" width={40} />
              ) : (
                <Button
                  color={darkBackground ? 'inherit' : 'primary'}
                  component={Link}
                  onClick={() =>
                    trackEvent('Sign in page redirection', 'Clicked', 'CTA sign in header')
                  }
                  sx={darkBackground ? {} : { bgcolor: 'rgba(255, 255, 255, 0.5)' }}
                  to={`/${currentLanguage}/sign-in`}
                  variant="outlined"
                >
                  {data.signin_button_label?.text || t('commons.actions.sign_in')}
                </Button>
              )}
            </Box>
          )}
          <IconButton
            aria-label="menu"
            color="inherit"
            edge="end"
            onClick={() => toggleDrawer(!drawerOpen)}
            sx={{ display: smallDevice ? 'flex' : 'none', flexShrink: 0 }}
          >
            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const HeaderItemsStyle = css`
  align-items: center;
  border-color: transparent;
  border-style: solid;
  border-width: 2px 0;
  display: flex;
  padding: 0 10px;

  &:not(.active) {
    border-radius: 8px;
    min-height: 36px;
    padding: 4px 10px;
  }

  &.active:not(.child-link) {
    align-self: stretch;

    &:not(.white) {
      border-bottom: ${({ theme }) => `2px solid ${theme.palette.secondary.main}`};
      color: ${({ theme }) => theme.palette.secondary.main};
    }

    &.white {
      border-bottom: 2px solid #fff;
    }
  }

  &.child-link {
    height: 56px;

    &.active {
      color: ${({ theme }) => theme.palette.secondary.main};

      .child-link-icon {
        display: none;
      }
    }

    &:not(.active) {
      .child-link-active-icon {
        display: none;
      }
    }
  }

  &:not(.active):hover {
    &:not(.white) {
      background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.1)};
    }

    &.white {
      background-color: ${alpha('#fff', 0.1)};
    }
  }
`;

const NavLink = styled(StyledLink)`
  ${HeaderItemsStyle}

  &.icon {
    padding: 4px 10px;
  }
`;

const NavButton = styled(MuiButton)`
  && {
    ${HeaderItemsStyle}
    border-radius: 0;
    transition: none;
  }
`;

export default Header;
