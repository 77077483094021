import { Geogroup, compare } from '@geovelo-frontends/commons';
import { Box, Typography } from '@mui/material';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button';
import { SearchIcon } from '../../components/icons';
import { AppContext } from '../../context';

import Item from './item';

function SuggestedCommunities(): JSX.Element {
  const [geogroups, setGeogroups] = useState<Geogroup[]>();
  const {
    user: { geogroups: userGeogroups },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  useEffect(() => {
    if (userGeogroups) {
      const automaticJoinedGeogroups = userGeogroups
        .filter(({ status, group: { type } }) => status === 'automaticallyJoin' && type === 'city')
        .sort((a, b) => {
          return (
            compare(a.stats, b.stats) ||
            a.group.title.toLowerCase().localeCompare(b.group.title.toLowerCase())
          );
        });
      setGeogroups(automaticJoinedGeogroups.slice(0, 8).map(({ group }) => group));
    } else {
      setGeogroups(undefined);
    }
  }, [userGeogroups]);

  if (geogroups && geogroups.length === 0) return <></>;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography fontSize="1.5rem" fontWeight={700}>
        {t('geovelo.communities.suggested_communities.title')}
      </Typography>
      <Box alignItems="stretch" display="flex" flexWrap="wrap" gap={3}>
        {geogroups
          ? geogroups.map((group) => <Item geogroup={group} key={group.id} />)
          : [1, 2, 3].map((key) => <Item key={key} />)}
      </Box>
      <Box alignSelf="center" marginTop={3}>
        <Button
          color="primary"
          component={Link}
          size="medium"
          startIcon={<SearchIcon />}
          to={`/${currentLanguage}/communities/search`}
          variant="outlined"
        >
          {t('geovelo.communities.actions.search')}
        </Button>
      </Box>
    </Box>
  );
}

export default SuggestedCommunities;
