import {
  Autocomplete,
  FileInput,
  GeocoderService,
  Geogroup,
  GeogroupService,
  LinkIcon,
  Place,
  TFile,
  TGeogroupType,
} from '@geovelo-frontends/commons';
import { Check } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Button from '../../components/button';
import Dialog from '../../components/dialog';
import { AppContext } from '../../context';
import environment from '../../environment';

const types: Array<{ key: TGeogroupType; titleKey: string }> = [
  { key: 'company', titleKey: 'geovelo.communities.form_dialog.types.company' },
  { key: 'association', titleKey: 'geovelo.communities.form_dialog.types.association' },
  { key: 'school', titleKey: 'geovelo.communities.form_dialog.types.school' },
  { key: 'private', titleKey: 'geovelo.communities.form_dialog.types.private' },
];

const sizeRanges: { [key in TGeogroupType]: Array<{ key: string; min?: number; max?: number }> } = {
  company: [],
  school: [
    { key: 'schoolLessThan50', max: 50 },
    { key: 'schoolBetween50and100', min: 51, max: 100 },
    { key: 'schoolBetween100and500', min: 101, max: 500 },
    { key: 'schoolMoreThan500', min: 501 },
  ],
  association: [
    { key: 'associationLessThan50', max: 50 },
    { key: 'associationBetween50and100', min: 51, max: 100 },
    { key: 'associationBetween100and500', min: 101, max: 500 },
    { key: 'associationMoreThan500', min: 501 },
  ],
  city: [
    { key: 'cityLessThan30000', max: 29_999 },
    { key: 'cityBetween30000and70000', min: 30_000 },
    { key: 'cityBetween70000and300000', min: 70_000, max: 299_999 },
    { key: 'cityMoreThan300000', min: 300_000 },
  ],
  private: [],
  other: [],
};

interface IValues {
  type: TGeogroupType;
  name: string;
  sizeRangeIndex: number;
  address: Place | null;
  automaticChallenge: boolean;
  description: string;
  linkLabel: string;
  linkUrl: string;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: (geogroup?: Geogroup) => void;
  geogroup?: Geogroup;
};

function UserCommunityFormDialog({ geogroup, onClose, ...props }: TProps): JSX.Element {
  const [selectedTmpType, selectTmpType] = useState<TGeogroupType | undefined>();
  const [selectedType, selectType] = useState<TGeogroupType | undefined>(geogroup?.type);
  const [icon, setIcon] = useState<TFile | undefined>(
    geogroup?.photo
      ? {
          url: `${geogroup.photo}`,
          name: geogroup.photo.substring(geogroup.photo.lastIndexOf('/') + 1),
        }
      : undefined,
  );
  const [bannerPicture, setBannerPicture] = useState<TFile | undefined>(
    geogroup?.bannerPicture
      ? {
          url: `${geogroup.bannerPicture}`,
          name: geogroup.bannerPicture.substring(geogroup.bannerPicture.lastIndexOf('/') + 1),
        }
      : undefined,
  );
  const {
    user: { geogroups: userGeogroups },
    actions: { setUserGeogroups },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    setFieldValue,
    resetForm,
    handleChange,
    handleSubmit,
  } = useFormik<IValues>({
    initialValues: {
      type: geogroup?.type || 'private',
      name: geogroup?.title || '',
      sizeRangeIndex: geogroup
        ? sizeRanges[geogroup.type].findIndex((range) => range.max === geogroup.sizeRange?.max) || 0
        : 0,
      address: geogroup?.place || null,
      automaticChallenge:
        geogroup?.automaticChallengeStatus !== undefined
          ? geogroup.automaticChallengeStatus === 'disabled'
            ? false
            : true
          : true,
      description: geogroup?.description || '',
      linkUrl: geogroup?.link?.url || '',
      linkLabel: geogroup?.link?.label || '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
    }),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit,
  });

  useEffect(() => {
    if (props.open) {
      selectTmpType(undefined);
      selectType(geogroup?.type);

      setIcon(
        geogroup?.photo
          ? {
              url: `${geogroup.photo}`,
              name: geogroup.photo.substring(geogroup.photo.lastIndexOf('/') + 1),
            }
          : undefined,
      );

      setBannerPicture(
        geogroup?.bannerPicture
          ? {
              url: `${geogroup.bannerPicture}`,
              name: geogroup.bannerPicture.substring(geogroup.bannerPicture.lastIndexOf('/') + 1),
            }
          : undefined,
      );

      resetForm({
        values: {
          type: geogroup?.type || 'private',
          name: geogroup?.title || '',
          sizeRangeIndex: geogroup
            ? sizeRanges[geogroup.type].findIndex(
                (range) => range.max === geogroup.sizeRange?.max,
              ) || 0
            : 0,
          address: geogroup?.place || null,
          automaticChallenge:
            geogroup?.automaticChallengeStatus !== undefined
              ? geogroup.automaticChallengeStatus === 'disabled'
                ? false
                : true
              : true,
          description: geogroup?.description || '',
          linkUrl: geogroup?.link?.url || '',
          linkLabel: geogroup?.link?.label || '',
        },
      });
      if (geogroup?.place && !geogroup.place.address) getAddress(geogroup?.place);
    }
  }, [props.open]);

  async function getAddress({ point }: Place) {
    const place = await GeocoderService.reverseGeocode(undefined, point);
    setFieldValue('address', place);
  }

  async function onSubmit(
    {
      type,
      name,
      sizeRangeIndex,
      address,
      automaticChallenge,
      description,
      linkUrl,
      linkLabel,
    }: IValues,
    { setSubmitting }: FormikHelpers<IValues>,
  ) {
    if (!selectedType || selectedType === 'company') return;

    setSubmitting(true);

    try {
      const { min, max } = sizeRanges[selectedType]?.[sizeRangeIndex] || {
        min: undefined,
        max: undefined,
      };
      if (geogroup) {
        const updatedGeogroup = await GeogroupService.updateGeogroup(geogroup.id, {
          type,
          name,
          min,
          max,
          address,
          icon: icon instanceof File || icon === null ? icon : undefined,
          bannerPicture:
            bannerPicture instanceof File || bannerPicture === null ? bannerPicture : undefined,
          automaticChallengeStatus: automaticChallenge ? 'automaticDistance' : 'disabled',
          description,
          linkUrl,
          linkLabel: linkUrl ? linkLabel : '',
        });

        onClose(updatedGeogroup);
      } else {
        const newGeogroup = await GeogroupService.addGeogroup({
          type: selectedType,
          name,
          min,
          max,
          address,
          icon: icon instanceof File || icon === null ? icon : undefined,
          automaticChallengeStatus: automaticChallenge ? 'automaticDistance' : 'disabled',
          description,
          linkUrl,
          linkLabel: linkUrl ? linkLabel : '',
        });

        if (selectedType !== 'city') {
          const userGeogroup = await GeogroupService.getUserGeogroup(newGeogroup.id);

          if (userGeogroups) setUserGeogroups([userGeogroup, ...userGeogroups]);
          onClose(newGeogroup);
        } else {
          enqueueSnackbar(t('geovelo.communities.form_dialog.request_sent'), {
            variant: 'success',
          });
          onClose();
        }
      }
    } catch {
      enqueueSnackbar(
        t('geovelo.communities.form_dialog.server_error', {
          context: geogroup ? 'update' : selectedType === 'city' ? 'request' : '',
        }),
        { variant: 'error' },
      );
    }

    setSubmitting(false);
  }

  return (
    <Dialog
      fullWidth
      maxWidth={selectedType ? (selectedType === 'company' ? 'sm' : 'xs') : 'md'}
      onClose={(_, reason) => (!isSubmitting || reason !== 'backdropClick') && onClose()}
      {...props}
    >
      <DialogTitle>
        <Trans
          components={[<span key={0} style={{ color: '#2ac682' }} />]}
          context={geogroup ? 'update' : selectedType || 'hub'}
          i18nKey="geovelo.communities.form_dialog.title"
        />
      </DialogTitle>
      {selectedType ? (
        selectedType === 'company' ? (
          <>
            <DialogContent>
              <Typography>
                <Trans
                  components={[<br key={0} />]}
                  i18nKey="geovelo.communities.form_dialog.description_company"
                />
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClose()} variant="outlined">
                {t('commons.actions.cancel')}
              </Button>
              <Button
                color="primary"
                component="a"
                href={`${environment.enterpriseUrl}subscribe`}
                rel="noreferrer"
                target="_blank"
                variant="contained"
              >
                {t('geovelo.communities.employee_card.actions.discover')}
              </Button>
            </DialogActions>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Box display="flex" flexDirection="column" gap={2}>
                {geogroup && (
                  <FormControl
                    disabled={isSubmitting}
                    margin="none"
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="type-input">
                      {t('geovelo.communities.form_dialog.type')}
                    </InputLabel>
                    <Select
                      inputProps={{
                        id: 'type-input',
                      }}
                      label={t('geovelo.communities.form_dialog.type')}
                      name="type"
                      onChange={(event) => {
                        if (event.target.value !== 'private') {
                          setFieldValue(
                            'sizeRangeIndex',
                            Math.min(
                              values.sizeRangeIndex,
                              sizeRanges[event.target.value as TGeogroupType].length - 1,
                            ),
                          );
                        } else {
                          setFieldValue('address', null);
                        }
                        handleChange(event);
                        selectType(event.target.value as TGeogroupType);
                      }}
                      size="small"
                      value={values.type}
                    >
                      {types
                        .filter(({ key }) => key !== 'company')
                        .map(({ key, titleKey }) => (
                          <MenuItem key={key} value={key}>
                            {t(titleKey)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                {selectedType !== 'private' && (
                  <FormControl
                    disabled={isSubmitting}
                    margin="none"
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="size-range-input">
                      {t('geovelo.communities.form_dialog.size_range')}
                    </InputLabel>
                    <Select
                      inputProps={{
                        id: 'size-range-input',
                      }}
                      label={t('geovelo.communities.form_dialog.size_range')}
                      name="sizeRangeIndex"
                      onChange={handleChange}
                      size="small"
                      value={values.sizeRangeIndex}
                    >
                      {sizeRanges[selectedType].map(({ key, min, max }, index) => (
                        <MenuItem key={key} value={index}>
                          {t(
                            min !== undefined
                              ? max !== undefined
                                ? 'geovelo.communities.form_dialog.size_ranges.between'
                                : 'geovelo.communities.form_dialog.size_ranges.more_than'
                              : 'geovelo.communities.form_dialog.size_ranges.less_than',
                            { context: selectedType, min, max },
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <TextField
                  required
                  disabled={isSubmitting}
                  error={touched.name && Boolean(errors.name)}
                  label={t('geovelo.communities.form_dialog.name')}
                  margin="none"
                  name="name"
                  onChange={handleChange}
                  rows={3}
                  size="small"
                  value={values.name}
                  variant="outlined"
                />
                {selectedType !== 'private' && (
                  <Autocomplete
                    defaultValue={values.address}
                    disabled={isSubmitting}
                    error={touched.address && Boolean(errors.address)}
                    label={t('geovelo.communities.form_dialog.address') || ''}
                    onSelect={(address) => setFieldValue('address', address)}
                    size="small"
                  />
                )}
                <Box marginTop={-1}>
                  <Typography color="textSecondary" sx={{ marginLeft: 2 }} variant="caption">
                    {t('geovelo.communities.form_dialog.logo')}
                  </Typography>
                  <FileInput
                    disabled={isSubmitting}
                    file={icon}
                    onChange={setIcon}
                    size="small"
                    type="image"
                  />
                </Box>
                <Box marginTop={-1}>
                  <Typography color="textSecondary" sx={{ marginLeft: 2 }} variant="caption">
                    {t('geovelo.communities.form_dialog.banner')}
                  </Typography>
                  <FileInput
                    disabled={isSubmitting}
                    file={bannerPicture}
                    onChange={setBannerPicture}
                    size="small"
                    type="image"
                  />
                </Box>
                <TextField
                  fullWidth
                  multiline
                  disabled={isSubmitting}
                  error={touched.description && Boolean(errors.description)}
                  id="description"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 500 }}
                  label={t('geovelo.communities.form_dialog.description')}
                  margin="none"
                  name="description"
                  onChange={handleChange}
                  rows={2}
                  value={values.description}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  disabled={isSubmitting}
                  error={touched.linkUrl && Boolean(errors.linkUrl)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  label={t('geovelo.communities.form_dialog.link')}
                  margin="none"
                  name="linkUrl"
                  onChange={handleChange}
                  size="small"
                  type="url"
                  value={values.linkUrl}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  disabled={isSubmitting}
                  InputLabelProps={{ shrink: true }}
                  label={t('geovelo.communities.form_dialog.link_text')}
                  margin="none"
                  name="linkLabel"
                  onChange={handleChange}
                  size="small"
                  value={values.linkLabel}
                  variant="outlined"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.automaticChallenge}
                      disabled={isSubmitting}
                      name="automaticChallenge"
                      onChange={handleChange}
                      size="small"
                    />
                  }
                  label={t('geovelo.communities.form_dialog.automatic_challenges')}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isSubmitting}
                onClick={() => onClose()}
                type="reset"
                variant="outlined"
              >
                {t('commons.actions.cancel')}
              </Button>
              <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
                {t(
                  geogroup
                    ? 'geovelo.communities.actions.update'
                    : 'geovelo.communities.actions.create',
                  { context: selectedType },
                )}
              </Button>
            </DialogActions>
          </form>
        )
      ) : (
        <>
          <DialogContent>
            <Box columnGap={2} display="flex" flexWrap="wrap" rowGap={1}>
              {types.map(({ key, titleKey }) => {
                const active = key === selectedTmpType;

                return (
                  <Button
                    color="inherit"
                    endIcon={active && <Check sx={{ color: '#2AC682' }} />}
                    key={key}
                    onClick={() => selectTmpType(key)}
                    sx={{
                      bgcolor: active ? '#ECFBF4' : undefined,
                      borderColor: active ? '#2AC682' : '#DDE0E7',
                      color: '#212121',
                    }}
                    variant="outlined"
                  >
                    {t(titleKey)}
                  </Button>
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose()} variant="outlined">
              {t('commons.actions.cancel')}
            </Button>
            <Button
              color="primary"
              disabled={!selectedTmpType}
              onClick={() => selectType(selectedTmpType)}
              variant="contained"
            >
              {t('commons.actions.next')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default UserCommunityFormDialog;
