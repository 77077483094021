import { Report, ReportService } from '@geovelo-frontends/commons';
import {
  Box,
  Card,
  CardHeader,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AppContext } from '../context';

import Button from './button';
import Dialog from './dialog';
import { AccountIcon, SendIcon } from './icons';

interface IValues {
  comment: string;
}

type TProps = Omit<DialogProps, 'onClose' | 'onChange'> & {
  report: Report;
  onChange: (report: Report) => void;
  onClose: () => void;
};

function CommentsDialog({ report, onChange, onClose, ...props }: TProps): JSX.Element {
  const contentRef = useRef<HTMLDivElement>(null);
  const {
    user: { current: currentUser },
    report: { types },
  } = useContext(AppContext);
  const { isSubmitting, isValid, values, touched, errors, resetForm, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: { comment: '' },
      validationSchema: Yup.object().shape({
        comment: Yup.string().min(10).required(),
      }),
      enableReinitialize: true,
      validateOnChange: true,
      onSubmit,
    });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.open) {
      resetForm({ values: { comment: '' } });
      setTimeout(
        () =>
          contentRef.current?.scrollTo({
            top: contentRef.current.clientHeight,
            behavior: 'smooth',
          }),
        200,
      );
    }
  }, [props.open]);

  async function onSubmit({ comment }: IValues, { setSubmitting }: FormikHelpers<IValues>) {
    setSubmitting(true);

    try {
      const review = await ReportService.addReview({ reportId: report.id, comment });
      if (currentUser && review.creator) review.creator.id = currentUser.id;

      report.reviews.push(review);
      resetForm({ values: { comment: '' } });
      onChange(report);
    } catch {
      enqueueSnackbar(t('geovelo.comments_dialog.not_sent'));
    }

    setSubmitting(false);
  }

  return (
    <Dialog
      fullWidth
      disableEscapeKeyDown={isSubmitting}
      maxWidth="sm"
      onClose={(_, reason) => (!isSubmitting || reason !== 'backdropClick') && onClose()}
      {...props}
    >
      <DialogTitle>
        {t('geovelo.comments_dialog.title', {
          title: `${t(
            types?.find((value) => value.code === report.typeCode)?.titleKey ||
              'commons.report.default_title',
          )} #${report.id}`,
        })}
      </DialogTitle>
      <DialogContent dividers ref={contentRef}>
        {report.reviews
          .sort((a, b) => (a.created.isBefore(b.created) ? -1 : 1))
          .map(({ id, created, creator, comment }) => {
            if (!comment) return <Fragment key={id} />;

            const isOwn = currentUser && creator && creator.id === currentUser.id;

            return (
              <StyledCard className={isOwn ? 'own' : ''} key={id} variant="outlined">
                <CardHeader
                  avatar={<AccountIcon color={isOwn ? 'primary' : 'secondary'} />}
                  subheader={t(
                    isOwn
                      ? 'geovelo.comments_dialog.written_by_you_on'
                      : creator
                      ? 'geovelo.comments_dialog.written_by_on'
                      : 'geovelo.comments_dialog.written_on',
                    {
                      username: creator?.username,
                      date: created.format('LL'),
                      time: created.format('LT'),
                    },
                  )}
                  title={comment}
                />
              </StyledCard>
            );
          })}
        <StyledForm onSubmit={handleSubmit}>
          <Box flexGrow={1}>
            <TextField
              fullWidth
              multiline
              disabled={isSubmitting}
              error={touched.comment && Boolean(errors.comment)}
              inputProps={{ min: 10 }}
              label={t('geovelo.comments_dialog.comment')}
              margin="dense"
              name="comment"
              onChange={handleChange}
              rows={3}
              value={values.comment}
              variant="outlined"
            />
          </Box>
          <Box flexShrink={0} marginLeft="16px">
            <Tooltip placement="bottom" title={t('commons.actions.send')}>
              <span>
                <IconButton
                  color="primary"
                  disabled={!values.comment || !isValid || isSubmitting}
                  size="medium"
                  type="submit"
                >
                  <SendIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </StyledForm>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={isSubmitting} onClick={onClose}>
          {t('commons.actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const StyledCard = styled(Card)`
  &:not(:first-of-type) {
    margin-top: 8px;
  }

  &.own {
    margin-left: 16px;

    .MuiCardHeader-root {
      flex-direction: row-reverse;
    }

    .MuiCardHeader-avatar {
      margin: 0 0 0 16px;
    }
  }

  &:not(.own) {
    margin-right: 16px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

export default CommentsDialog;
