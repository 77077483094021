import { GeoveloInlineWhiteIcon } from '@geovelo-frontends/commons';
import { Box, Divider, Typography } from '@mui/material';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FooterQuery,
  PrismicFooterLinksFragment,
  PrismicFooterSocialLinksFragment,
} from '../../../graphql-types';
import { AppStoreBadge, GooglePlayBadge } from '../../components/store-badges';
import usePrismicNodes from '../../hooks/prismic-nodes';

function Footer(): JSX.Element {
  const staticQuery = useStaticQuery<FooterQuery>(graphql`
    fragment PrismicFooterLinks on PrismicFooterDataBodyLinks {
      id
      slice_type
      items {
        bold
        item_link {
          link_type
          url
        }
        item_title {
          text
        }
      }
    }
    fragment PrismicFooterSocialLinks on PrismicFooterDataBodySociallinks {
      id
      slice_type
      items {
        item_icon {
          gatsbyImageData(placeholder: NONE, width: 24)
        }
        item_link {
          url
        }
        item_title {
          text
        }
      }
    }
    fragment FooterData on PrismicFooterData {
      background_color
      text_color
      body {
        ... on PrismicFooterDataBodyLinks {
          ...PrismicFooterLinks
        }
        ... on PrismicFooterDataBodySociallinks {
          ...PrismicFooterSocialLinks
        }
      }
      newsletter_title {
        text
      }
      newsletter_field_placeholder {
        text
      }
      newsletter_cta_title {
        text
      }
      privacy_policy_link_title {
        text
      }
      privacy_policy_link {
        link_type
        url
      }
      legal_notice_link_title {
        text
      }
      legal_notice_link {
        link_type
        url
      }
      eula_link_title {
        text
      }
      eula_link {
        link_type
        url
      }
    }
    query Footer {
      allPrismicFooter {
        nodes {
          _previewable
          lang
          data {
            ...FooterData
          }
        }
      }
    }
  `);
  const query = useMergePrismicPreviewData(staticQuery);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { data } = usePrismicNodes(query?.allPrismicFooter.nodes, { currentLanguage });

  if (!data) return <></>;

  const {
    background_color,
    text_color,
    body,
    // newsletter_title,
    // newsletter_field_placeholder,
    // newsletter_cta_title,
    privacy_policy_link_title,
    privacy_policy_link,
    legal_notice_link_title,
    legal_notice_link,
    eula_link_title,
    eula_link,
  } = data;

  return (
    <Box
      component="footer"
      display="flex"
      flexDirection="column"
      flexShrink={0}
      sx={{
        backgroundColor: background_color || '#212121',
        color: text_color || '#fff',
        zIndex: 1,
      }}
    >
      <Box
        alignSelf="center"
        display="flex"
        maxWidth={{ xs: 'calc(100% - 80px)', xl: 'calc(100% - 160px)' }}
        paddingBottom={5}
        paddingTop={{ xs: 5, lg: 8 }}
        paddingX={{ xs: 5, xl: 10 }}
        width={1384}
      >
        <Box
          alignItems={{ xs: 'center', lg: 'flex-start' }}
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
          gap={5}
          width="100%"
        >
          <Box
            alignItems={{ xs: 'center', lg: 'flex-start' }}
            alignSelf={{ xs: 'stretch', lg: 'flex-start' }}
            display="flex"
            flexDirection={{ xs: 'column', lg: 'row' }}
            flexGrow={1}
            gap={{ xs: 5, lg: 10 }}
          >
            <Box>
              <GeoveloInlineWhiteIcon style={{ height: '24px', width: 'auto' }} />
            </Box>
            <Box
              alignSelf={{ xs: 'stretch', lg: 'flex-start' }}
              display="flex"
              flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
              gap={5}
              justifyContent={{ xs: 'space-between', lg: 'flex-start' }}
            >
              {(
                body.filter(
                  ({ slice_type }) => slice_type === 'links',
                ) as PrismicFooterLinksFragment[]
              ).map(({ id, items }) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  key={id}
                  width={{
                    xs: '100%',
                    sm: 'calc((100% - 40px) / 2)',
                    md: 'auto',
                  }}
                >
                  {items.map(({ bold, item_title, item_link }, index) => {
                    const props =
                      item_link && item_link.url
                        ? item_link.link_type === 'Document'
                          ? { component: Link, to: item_link.url }
                          : { component: 'a', href: item_link.url }
                        : {};

                    return (
                      <Typography
                        color="inherit"
                        fontWeight={(bold && 700) || undefined}
                        key={index}
                        lineHeight={1.1}
                        sx={{ textDecoration: 'none' }}
                        variant="body2"
                        {...props}
                      >
                        {item_title?.text}
                      </Typography>
                    );
                  })}
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            alignItems={{ xs: 'flex-start', sm: 'center', lg: 'flex-start' }}
            alignSelf={{ xs: 'flex-start', sm: 'center', lg: 'flex-start' }}
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row', lg: 'column' }}
            gap={{ xs: 3, sm: 6, lg: 3 }}
            maxWidth="100%"
            width={280}
          >
            <Box display="flex" gap={2}>
              {(
                body.find(
                  ({ slice_type }) => slice_type === 'sociallinks',
                ) as PrismicFooterSocialLinksFragment
              ).items.map(({ item_icon, item_title, item_link }, index) => {
                const icon = item_icon && getImage(item_icon);
                if (!icon) return <Fragment key={index} />;

                return (
                  <a
                    href={item_link?.url || ''}
                    key={index}
                    rel="noreferrer"
                    style={{ height: 24, width: 24 }}
                    target="_blank"
                  >
                    <GatsbyImage alt={item_title?.text || ''} image={icon} />
                  </a>
                );
              })}
            </Box>
            <Box display="flex" gap={2}>
              <AppStoreBadge height={40} trackingName="Footer CTA home page" />
              <GooglePlayBadge height={40} trackingName="Footer CTA home page" />
            </Box>
            {/* <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="caption">
              {newsletter_title?.text || t('geovelo.footer.newsletter.title')}
            </Typography>
            <Box
              alignItems="center"
              borderRadius={4}
              display="flex"
              gap={2}
              paddingLeft={2}
              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.12)' }}
            >
              <InputBase
                name="email"
                placeholder={
                  newsletter_field_placeholder?.text ||
                  t('geovelo.footer.newsletter.placeholder') ||
                  ''
                }
                sx={{ color: '#fff', flexGrow: 1, fontSize: '0.9rem' }}
                type="email"
              />
              <Button
                sx={{
                  borderRadius: 4,
                  color: '#000',
                  height: 32,
                  paddingY: 0,
                  textTransform: 'initial',
                  whiteSpace: 'nowrap',
                }}
                variant="contained"
              >
                {newsletter_cta_title?.text || t('geovelo.footer.newsletter.action')}
              </Button>
            </Box>
          </Box> */}
          </Box>
        </Box>
      </Box>
      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
      <Box
        alignItems="center"
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        gap={{ xs: 0, sm: 2 }}
        justifyContent="center"
        paddingX={2}
      >
        <Box alignItems="center" display="flex" height={52}>
          <Typography
            color="inherit"
            component={Link}
            sx={{ textDecoration: 'none' }}
            to={privacy_policy_link?.url || '/'}
            variant="body2"
          >
            {privacy_policy_link_title?.text || t('geovelo.navigation.privacy_policy')}
          </Typography>
        </Box>
        <Box
          borderLeft="thin solid rgba(255, 255, 255, 0.2)"
          display={{ xs: 'none', sm: 'block' }}
          height={16}
        />
        <Box alignItems="center" display="flex" height={52}>
          <Typography
            color="inherit"
            component={Link}
            sx={{ textDecoration: 'none' }}
            to={legal_notice_link?.url || '/'}
            variant="body2"
          >
            {legal_notice_link_title?.text || t('geovelo.navigation.legal_notice')}
          </Typography>
        </Box>
        <Box
          borderLeft="thin solid rgba(255, 255, 255, 0.2)"
          display={{ xs: 'none', sm: 'block' }}
          height={16}
        />
        <Box alignItems="center" display="flex" height={52}>
          <Typography
            color="inherit"
            component={Link}
            sx={{ textDecoration: 'none' }}
            to={eula_link?.url || '/'}
            variant="body2"
          >
            {eula_link_title?.text || t('geovelo.navigation.eula')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
